import React, { useEffect, useState } from "react";
import CardPage from "../../../layout/CardPage";
import { useGetListadoDocumentado } from "./useGetListadoDocumentado";
import { Item } from "./_Item";
import { Button, Col, Row, Select } from "antd";
import { useGetUsuariosEscotel } from "./useGetUsuariosEscotel";
import { useSelector } from "react-redux";

export const Documentando = () => {
  const [lista, setLista] = useState([]);
  const [usuarioID, setUsuarioID] = useState(null);
  useGetListadoDocumentado();
  const { listadoDocumentando } = useSelector((state) => state.ptReducer);
  const { usuariosEscotel } = useSelector((state) => state.ptReducer);
  useGetUsuariosEscotel();

  useEffect(() => {
    setLista(listadoDocumentando);
  }, [listadoDocumentando]);

  const handleChange = (value) => {
    setUsuarioID(value);
    const l = listadoDocumentando.filter((item) => item.usuarioAsignadoID === value);
    setLista(l);
  };


  const handleClick = () => {
    setUsuarioID(null);
    setLista(listadoDocumentando);
  }

  //
  return (
    <CardPage titulo="Documentando">
      <Row justify="end" gutter={20}>
        <Col span={8}>
          <Select
            value={usuarioID}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="small"
            style={{ width: "100%" }}
            options={usuariosEscotel}
            placeholder="Filtrar..."
            onChange={handleChange}
          />
        </Col>
        <Col span={2}>
          <Button disabled={!usuarioID} onClick={handleClick} size="small">Borrrar Filtro</Button>
        </Col>

      </Row>

      <Row className="rowHeader">
        <Col xs={8} lg={6} xl={2} className="tituloColumna">
          ID
        </Col>
        <Col xs={8} lg={6} xl={3} className="tituloColumna">
          Ultimo Conatto
        </Col>
        <Col xs={8} lg={6} xl={3} className="tituloColumna">
          Siniestro
        </Col>
        <Col xs={24} lg={12} xl={5} className="tituloColumna">
          Auto
        </Col>

        <Col xs={24} lg={12} xl={6} className="tituloColumna">
          Asignado
        </Col>
        <Col xs={24} lg={12} xl={4} className="tituloColumna">
          Asignar
        </Col>
        <Col xs={1} className="tituloColumna"></Col>
      </Row>

      {lista.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </CardPage>
  );
};
