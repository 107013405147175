import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { UltimoContacto } from "./_UltimoContacto";
import { UsuarioAsignado } from "./_UsuarioAsignado";
import { Asignar } from "./_Asignar";
import { EyeFilled } from "@ant-design/icons";

export const Item = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Row
      gutter={[10, 10]}
      className="rowItemElemento"
      style={{ cursor: "pointer" }}
    >
      <Col xs={8} lg={6} xl={2}>
        {item.idUnico}
      </Col>
      <Col xs={8} lg={6} xl={3}>
        <UltimoContacto item={item} />
      </Col>
      <Col xs={8} lg={6} xl={3}>
        {item.numSiniestro}
      </Col>
      <Col xs={24} lg={12} xl={5}>
        {item.marca} / {item.tipo}: {item.modelo}
      </Col>

      <Col xs={24} lg={12} xl={6}>
        {item.usuarioAsignado ? (
          <UsuarioAsignado
            fechaUsuarioAsignado={item.fechaUsuarioAsignado}
            usuarioAsignado={item.usuarioAsignado}
          />
        ) : (
          "-"
        )}
      </Col>
      <Col xs={24} lg={12} xl={4}>
        <Asignar id={item.id} />
      </Col>
      <Col xs={1} style={{textAlign: 'center'}}>
        <EyeFilled
          onClick={() => navigate(`/PTDetalle/${item.id}`)}
          className="botonIcono"
          style={{ fontSize: "1.5em", color: "orange" }}
        />
      </Col>
    </Row>
  );
};
