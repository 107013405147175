import React from "react";
import { dateFromString } from "../../../utils/dateFromString";

export const UsuarioAsignado = ({ usuarioAsignado, fechaUsuarioAsignado }) => {
  return (
    <div>
      {dateFromString(fechaUsuarioAsignado).soloFecha} | {usuarioAsignado}
    </div>
  );
};
