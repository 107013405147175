import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { startGetUsuariosEscotel } from '../../../context/actions/ptActions';

export const useGetUsuariosEscotel = () => {
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(startGetUsuariosEscotel())
    }, [])
    
}
