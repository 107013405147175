import { Button, Col, Row, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  startAsignaUsuario } from "../../../context/actions/ptActions";

export const Asignar = ({ id }) => {
  const dispatch = useDispatch();
  const [usuarioID, setUsuarioID] = useState(null);
  const { usuariosEscotel } = useSelector((state) => state.ptReducer);
  const submit = () => {
    const body = {
      id,
      usuarioID,
    };
    dispatch(startAsignaUsuario(body));
    setUsuarioID(null);
  };

  return (
    <Row>
      <Col span={20}>
        <Select
          value={usuarioID}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="small"
          style={{ width: "100%" }}
          options={usuariosEscotel}
          placeholder="seleccione..."
          onChange={(value) => setUsuarioID(value)}
        />
      </Col>
      <Col span={4}>
        <Button
          onClick={submit}
          disabled={!usuarioID}
          size="small"
          type="default"
        >
          ok
        </Button>
      </Col>
    </Row>
  );
};
